* {
  margin: 0;
  padding: 0;
  }

:root {
  background-image: linear-gradient(to bottom right, #e7f0f3, #adcfd260);
  
}
html.dark-bg {
  background-image: linear-gradient(to bottom right, #354D5A, #25363f);
  height: 100vh;
  padding-top: 2px;
}

$light-text-header: #333;
$light-text-options: #555;
$light-element: #fbfbf9;
$light-bg: white;
$light-bgImage: linear-gradient(to bottom right, #e7f0f3, #adcfd260);

$dark-text-header: #fff;
$dark-text-options: #ddd;
$dark-element: #232426;
$dark-bg: #4d5156ff;
$dark-bgImage: linear-gradient(to bottom right, #4d5156ff, #25363f);


header {
  border-bottom: 2px solid #b87887;
  background-color: $light-bg;
  min-height: 64px;
  width: 100vw;
  display: flex;
  justify-content: right;
}

.darkHeader {
  border-bottom: 2px solid #b87887;
  background-color: #25363f;
  min-height: 64px;
  width: 100vw;
  display: flex;
  justify-content: right;
  transition: 0.02 ease-in-out;
}

.HomeBg {
  height: 100vh;
  width: 100vw;
  padding-top: 100px;
  background-image: url("https://images.unsplash.com/photo-1623305463957-df17547327cb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"), 
  linear-gradient(to bottom right, rgba(187, 188, 198),rgba(32,178,170, 0.2));
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.DarkHomeBg {
  height: 100vh;
  width: 100vw;
  padding-top: 100px;
  background-image: url("https://images.unsplash.com/photo-1623305463957-df17547327cb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"), 
  linear-gradient(to bottom right, rgba(4, 58, 58, 0.75),rgb(21, 21, 21));
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body {
  justify-content: center;
  display: flex;
  height: 100vh;
  font-size: 17px;
  color: #4B4D54;
  overflow: hidden;
  font-family: 'Source Sans Pro', sans-serif;
  z-index: 5;
}

a:link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerLogo{
  text-decoration: none;
  float: left;
  color: lightseagreen;
  font-weight: 800;
  font-size: 16px;
  padding-left: 10vw;
  padding-right: 55vw;
  -webkit-transition: all .2s ease-in-out;
}

.headerLogo:hover{
  font-size: 16px;
  background-color: lightseagreen;
  border: none;
  border-radius: 2px;
  color: white; 
}

.NavItem {
  background-color: #ffffff00;
  // color: #78909cff;
  color: lightseagreen;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 12px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
}

.NavItem:hover {
  font-size: 16px;
  background-color: lightseagreen;
  border: none;
  border-radius: 2px;
  color: white; 
}

p {
  display: flex;
  justify-content: center;
  color: #778899;
  letter-spacing: 1px;
  font-weight: 400;
}

h1 {
  font-family: Courier, monospace;
  color: lightseagreen;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

h2 {
  margin-top: 2vh;
  margin-bottom: 1vh;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: lightseagreen;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
}

.backbtn {
  float: left;
  text-decoration: none;
  color: slategrey;
  font-size: 2.5rem;
  font-weight: bolder;
  position: absolute;
  margin-top: 2%;
  margin-left: 5%;
  border: 2px solid #00000010;
  border-radius: 10px;
  padding: 10px
}

.backbtn:hover {
  background-color: #eeeeee;
  color: $light-text-header;
}

.button {
  background-color: #78909cff;
  color: whitesmoke;
  font-size: 1.5em;
  border-radius: 10px;
  transition: 150ms;
  border: none;
  padding: 0.75rem 5rem;
  margin-top: 10vw;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.button:hover {
  background-color: lightseagreen;
  color: white;
  box-shadow: 0px 0px 5px 2px #00000050;
}

.container {
  max-width: 900px;
  margin: 1rem 13%;
}

.cardSlider {
  display: flex;
  justify-content: center;
}

.sliderNav {
  margin: 3%;
  display: flex;
  justify-content:space-around;
}

.sliderBtn-L {
  background-color: #78909cff;
  color: whitesmoke;
  font-size: 1rem;
  border-radius: 50px;
  width: 4vw;
  height: 4vw;
  transition: 150ms;
  cursor: pointer;
  border: none;
}

.sliderBtn-R {
  background-color: #78909cff;
  color: whitesmoke;
  font-size: 1rem;
  border-radius: 50px;
  width: 4vw;
  height: 4vw;
  transition: 150ms;
  cursor: pointer;
  border: none;
}

.sliderBtn-L:hover {
background-color: lightseagreen;
color: white;
box-shadow: 0px 0px 5px 2px #00000050;
}
.sliderBtn-R:hover {
background-color: lightseagreen;
color: white;
box-shadow: 0px 0px 5px 2px #00000050;
}

.card-grid {
  display: grid;
  align-items: center;
  gap: 1rem;
}

//#################### CARD STYLES - LIGHT #################### 

.question {
  color: $light-text-header;
  font-size: 2vw;
  font-family:'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: left;
  padding: 0 20px;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  margin-top: 1em;
  position: relative;
  box-shadow: 0 0 5px 2px #00000030;
  background-color: #fbfbf9;
  transform-style: preserve-3d;
  transition: 150ms;
  height: 30vw;
  width: 50vw;
  cursor: pointer;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0) )
    translateY(var(--translate-y, 0));
}

.card:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px #00000050;
}

.card.flip {
  --rotate-y: 180deg;
  background-color: white;
}

.card .front {
  left: 0;
}

.card .front,
.card .back {
  position: absolute;
  padding: 3%;
  backface-visibility: hidden;
  width: 94%;
}

.card .back {
  color: $light-text-header;
  font-size: 2vw;
  font-family:'Times New Roman', Times, serif;
  font-weight: 700;
  border-bottom: 3px solid #b87887;
  transform: rotateY(180deg);
  width: fit-content;
}

.flashcard-options {
  border-top: 2px solid #b87887;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.flashcard-option {
  margin-top: 0.5rem;
  color: $light-text-options;
  font-size: 1.5vw;
  border-bottom: 1px solid #b7e0e6;
  text-indent: 2rem;
}

.flashcard-option:first-child {
  margin-top: 0;
}

//#################### CARD STYLES - DARK #################### 

.question-dark {
  color: #eeeeee;
  font-size: 2vw;
  font-family:'Times New Roman', Times, serif;
  font-weight: 700;
  text-align: left;
  padding: 0 20px;
}

.card-dark {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #333;
  margin-top: 1em;
  position: relative;
  box-shadow: 0 0 5px 2px #00000030;
  background-color: #202124;
  transform-style: preserve-3d;
  transition: 150ms;
  height: 30vw;
  width: 50vw;
  cursor: pointer;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0) )
    translateY(var(--translate-y, 0));
}

.card-dark:hover {
  --translate-y: -2px;
  box-shadow: 0 0 10px 5px #00000050;
}

.card-dark.flip {
  --rotate-y: 180deg;
  background-color: #121314;
}

.card-dark .front,
.card-dark .back {
  position: absolute;
  padding: 3%;
  backface-visibility: hidden;
  width: 94%;
}

.card-dark .back {
  color: #eeeeee;
  font-size: 2vw;
  font-family:'Times New Roman', Times, serif;
  font-weight: 700;
  border-bottom: 3px solid #b87887;
  transform: rotateY(180deg);
  width: fit-content;
}

.flashcard-options-dark {
  border-top: 2px solid #b87887;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.flashcard-option-dark {
  margin-top: 0.5rem;
  color: #ddd;
  font-size: 1.5vw;
  border-bottom: 1px solid #b7e0e67a;
  text-indent: 2rem;
}

.flashcard-option-dark:first-child {
  margin-top: 0;
}

//#################### DROPDOWN STYLES - DARK #################### 

.dropdown {
  width: 300px;
  font-size: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 10px;
  padding: 1em;
  box-shadow: 0 10px 25px rgba(0,0,0,.2);
  background-color: white;
  border: white solid 3px;
}
.dropdown:hover {
  border: lightseagreen solid 3px;
  box-shadow: 0 10px 25px rgba(0,0,0,.3);
}
.dropdown-dark {
  color: white;
  font-size: 1rem;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 10px;
  padding: 1em;
  box-shadow: 0 10px 25px rgba(0,0,0,.2);
  background-color: #ffffff00;
  // background-color: #788f9c;
  border: #bbb solid 3px;
  
  
}
.dropdown-dark:hover {
  background-color: #788f9c75;
  border: #eeeeee solid 3px;
  box-shadow: 0 10px 25px rgba(0,0,0,.3);
  transition: 150ms ease-in-out;
}


.loader {
  margin: auto;
  border: 20px solid #EAF0F6;
  border-radius: 50%;
  border-top: 20px solid lightseagreen;
  width: 200px;
  height: 200px;
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

//#################### FORM STYLES - LIGHT #################### 

.formStyles {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 10px;
  padding: 1em;
  box-shadow: 0 10px 25px rgba(0,0,0,.2);
  background-color: white;
  border: white solid 3px;
}

.formLabels {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.form-input {
  border: 3px solid #e7f0f3;
  border-radius: 10px ;
  flex-direction: column;
  padding: 10px;
}
.form-input:hover {
  border-radius: 10px ;
  padding: 10px;
  border: lightseagreen solid 3px;
  box-shadow: 0 10px 25px rgba(0,0,0,.3);
  -webkit-transition: all 0.2s ease-in-out;
}

/* input:required:invalid {
  border-color: #c00000;
} */

.form-button {
  background-color: #78909cff;
  color: whitesmoke;
  font-size: 1.5em;
  border-radius: 10px;
  transition: 150ms;
  border: none;
  padding: 0.75rem 5rem;
  margin-top: 5vw;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.form-button:hover {
  background-color: lightseagreen;
  color: white;
  box-shadow: 0px 0px 5px 2px #00000050;
}

//#################### FORM STYLES - DARK #################### 

.formStyles-dark {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 10px;
  padding: 1em;
  box-shadow: 0 10px 25px rgba(0,0,0,.2);
  background-color: #202124;
  border: #272727 solid 3px;
}

.formLabels-dark {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: #aaa;
}

.form-input-dark {
  border: 3px solid #272727 ;
  border-radius: 10px ;
  flex-direction: column;
  padding: 10px;
  background-color: #00000080;
}

.form-input-dark:hover {
  border-radius: 10px ;
  padding: 10px;
  border: lightseagreen solid 3px;
  box-shadow: 0 10px 25px rgba(0,0,0,.3);
  -webkit-transition: all 0.2s ease-in-out;
}

/* input:required:invalid {
  border-color: #c00000;
} */


//#################### LOGIN WITH GOOGLE BUTTON #################### 

.login-with-google-btn {
  display: block;
  justify-content: center;
  margin-top: 10vw;
  margin-left: auto;
  margin-right: auto;

  transition: background-color .3s, box-shadow .3s;
  padding: 1rem 4rem;
  border: none;
  border-radius: 10px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  
  color: whitesmoke;
  font-size: 1.25rem;
  transition: 150ms;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 1.5rem 1.25rem;
  background-color: #78909cff;

 
    

  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    background-color: white;
    color: #757575;
  }
}
